import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  topicData: [],
};

export const topicSlice = createSlice({
  name: "topic",
  initialState,
  reducers: {
    setTopicDetails: (state, action) => {
      state.loading = false;
      state.topicData = action.payload;
    },
  },
});

export const { setTopicDetails } = topicSlice.actions;

export default topicSlice.reducer;
