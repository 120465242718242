import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  langData: [],
};

export const langSlice = createSlice({
  name: "lang",
  initialState,
  reducers: {
    setLangDetails: (state, action) => {
      state.loading = false;
      state.langData = action.payload;
    },
  },
});

export const { setLangDetails } = langSlice.actions;

export default langSlice.reducer;
