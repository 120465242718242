import { apiRequest } from "utils/async/apiUtils";
import { message } from "antd";

export const loadSettingDetails = async (
  setResData = () => {},
  setLoaded = () => {}
) => {
  try {
    const res = await apiRequest("get", "site-settings/details");

    const data = res.data;
    if (data.status === 200) setResData(data.data);
    setLoaded(true);
    return data;
  } catch (err) {
    setLoaded(true);
  }
};

export const editSettings = async (data) => {
  try {
    const res = await apiRequest(
      "put",
      "site-settings/update",
      {
        body: {
          id: data.id,
          title: data.title,
          description: data.description,
          email: data.email,
          phone: data.phone,
          address: data.address,
          logo: data.fileList,
          // favicon: data.fileFavList,
        },
      },
      "multipart/form-data"
    );
    return res;
    message.success(res.data.message);
  } catch (error) {}
};
